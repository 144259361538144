import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {getTicketPriceWithoutTax} from '../../../../commons/selectors/tickets'

export interface InvoiceItem {
  id: string
  ticketDefinitionId: string
  quantity: number
  price: wix.events.Money
  name: string
  ticketDetails: wix.events.ticketing.TicketDetails
}

export const getGroupedInvoiceItems = (order: wix.events.ticketing.Order): InvoiceItem[] => {
  return order.tickets.reduce((groupedTickets, ticket) => {
    const existingTicket = groupedTickets.find(
      groupedTicket =>
        groupedTicket.ticketDefinitionId === ticket.ticketDefinitionId &&
        groupedTicket.ticketDetails?.pricingOptionId === ticket.ticketDetails?.pricingOptionId,
    )
    if (existingTicket) {
      existingTicket.quantity += 1
    } else {
      groupedTickets.push({
        id: ticket.ticketDetails?.pricingOptionId
          ? `${ticket.ticketDefinitionId}_${ticket.ticketDetails.pricingOptionId}`
          : ticket.ticketDefinitionId,
        quantity: 1,
        price: getTicketPriceWithoutTax(order, ticket),
        name: ticket.name,
        ticketDefinitionId: ticket.ticketDefinitionId,
        ticketDetails: ticket.ticketDetails,
      })
    }
    return groupedTickets
  }, [] as InvoiceItem[])
}

export const getTicketPriceText = (price: wix.events.Money, t: TFunction) =>
  Number(price.amount) ? getFormattedMoney(price) : t('ticketPrice.free')

export const getPlaceInfoFromTicket = (ticket: wix.events.ticketing.Ticket) => ({
  area: ticket?.ticketDetails?.areaName,
  table: ticket?.ticketDetails?.tableName,
})

export const getLabeledItemTicketPrice = (item: InvoiceItem, t: TFunction) => {
  let ticketPrice = getTicketPriceText(item.price, t)
  if (item.ticketDetails?.pricingOptionName) {
    ticketPrice = `${item.ticketDetails.pricingOptionName} - ${ticketPrice}`
  }
  return ticketPrice
}
